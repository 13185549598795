define("discourse/plugins/discourse-subscriptions/discourse/templates/subscribe", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="container">
    <div class="title-wrapper">
      <h1>
        {{i18n "discourse_subscriptions.subscribe.title"}}
      </h1>
    </div>
  
    <hr />
  
    {{outlet}}
  </div>
  */
  {
    "id": "xjY0iWTK",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"title-wrapper\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"discourse_subscriptions.subscribe.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"div\",\"h1\",\"i18n\",\"hr\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/subscribe.hbs",
    "isStrictMode": false
  });
});